<template lang="pug">
.wrapper
  .container.py-4.text-center
    img.waving-img.mx-auto(src="@/assets/monk-hi-user.svg" style="height: 12rem")
    .welcome-title.mb-2
      span {{ $t('connectShopify.hiOM', { firstName: getLoginFirstName }) }}
    .welcome-intro
      span {{ $t('connectShopify.doYouWantToConnect') }}
    .connect-box.mx-auto.font-size-1.mt-6.d-flex.align-items-center
      .d-flex.mb-6
        .connect-platform-box.ml-auto
          img.mx-auto(src="@/assets/admin/svg/platforms/shopify.svg" style="height: 3.75rem")
          .mt-4.mx-2.font-weight-500 {{ currentDomain }}
        img.mx-auto(src="@/assets/admin/svg/arrow-horizontal-swap.svg")
        .connect-platform-box.mr-auto
          img.mx-auto(src="@/assets/logo-mini.svg" style="height: 3.75rem")
          .mt-4.mx-2
            span.d-block.font-weight-500 {{ pendingShopifyInstall && pendingShopifyInstall.firstName }} {{ pendingShopifyInstall && pendingShopifyInstall.lastName }}
            span.d-block.account-email {{ accountEmail }}
      .select-subdomain.d-flex.align-items-center(v-if="isAgencyUser")
        om-body-text.no-sub-account.mb-5(bt400md v-if="subAccounts.length === 0") {{ $t('connectShopify.noSubAccount') }}
        om-body-text.col-12.mb-3(bt400md v-if="subAccounts.length > 0") {{ $t('connectShopify.selectSubAccount') }}
        om-select.col-12.mb-5(
          v-if="subAccounts.length > 0"
          :id="`connectShopify-selectSubAccount`"
          :options="subAccounts"
          :placeholder="$t('connectShopify.selectSubAccountDropDown')"
          optionKey="_id"
          optionText="name"
          v-model="selectedSubAccount"
        )
      .d-flex.align-content-center.justify-content-center.mb-6(
        v-if="isAgencyUser && subAccounts.length === 0"
      )
        om-button(primary tag="button" @click="goToAgencyDashboard") {{ $t('connectShopify.goToAgencyDashboard') }}
      .d-flex.align-content-center.justify-content-center.mb-6(
        v-if="!isAgencyUser || subAccounts.length"
      )
        om-button(
          primary
          tag="button"
          :loading="isLoading"
          @click="connectShopify"
          :disabled="isConnectDisabled"
        ) {{ $t('connectShopify.connect') }}
      .connect-another.d-flex.justify-content-center
        span.cursor-pointer.brand-link-underline(@click="connectAnother") {{ $t('connectShopify.connectAnother') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import FINALIZE_SHOPIFY_INSTALL from '@/graphql/FinalizeShopifyInstall.gql';
  import GET_SUBACCOUNTS from '@/graphql/GetSubAccounts.gql';
  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import REMOVE_SHOPIFY_INSTALL from '@/graphql/RemovePendingShopifyInstall.gql';
  import { track } from '@/services/xray';
  import { setAccountIdInCookie } from '../util';

  export default {
    name: 'ConnectShopify',
    data() {
      return {
        pendingShopifyInstall: null,
        loading: false,
        subAccounts: [],
        selectedSubAccount: null,
      };
    },
    computed: {
      ...mapGetters([
        'getPendingShopifyInstall',
        'isLoggedIn',
        'getAccountEmail',
        'getAccountName',
        'userRole',
        'getLoginFirstName',
        'isAgencyUser',
      ]),
      currentDomain() {
        return (
          this.pendingShopifyInstall?.liveDomain || this.pendingShopifyInstall?.myshopifyDomain
        );
      },
      isLoading() {
        return this.loading;
      },
      accountEmail() {
        return this.userRole === 'owner' ? this.getAccountEmail : this.getAccountName;
      },
      isConnectDisabled() {
        return this.isAgencyUser && (this.subAccounts.length === 0 || !this.selectedSubAccount);
      },
    },
    async created() {
      this.pendingShopifyInstall = this.getPendingShopifyInstall;

      if (!this.pendingShopifyInstall) {
        return this.$router.replace({ name: 'dashboard' });
      }

      if (!this.isLoggedIn()) {
        return this.$router.replace({ name: 'connect_shop', params: { shop: 'shopify' } });
      }
      if (this.isAgencyUser) {
        const {
          data: { subAccountData },
        } = await this.$apollo.query({
          query: GET_SUBACCOUNTS,
        });
        this.subAccounts = subAccountData?.subAccounts || [];
      }
    },
    methods: {
      async connectShopify() {
        this.loading = true;
        track('connectShopify-connectShopify');
        const { data: success } = await this.$apollo.query({
          query: FINALIZE_SHOPIFY_INSTALL,
          variables: {
            subAccountId:
              this.isAgencyUser && this.selectedSubAccount
                ? this.selectedSubAccount.databaseId
                : null,
          },
        });
        this.loading = false;

        if (this.isAgencyUser) {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_LOGIN_CREDENTIALS,
            variables: { databaseId: this.selectedSubAccount.databaseId },
          });

          const login = data.login;
          const userId = login.dataAccountId;
          setAccountIdInCookie(userId);
          this.$store.dispatch('login', {
            expiration: login.expiration,
            accountType: login.accountType,
            locale: login.locale,
            impersonate: true,
          });

          this.$router.replace({
            name: login.redirectRoute,
            params: { userId },
            query: {
              confirm_shopify_install: success ? 'success' : 'failed',
              domain: this.currentDomain,
            },
          });
        } else {
          this.$router.replace({
            name: 'dashboard',
            query: {
              confirm_shopify_install: success ? 'success' : 'failed',
              domain: this.currentDomain,
            },
          });
        }
      },
      connectAnother() {
        track('connectShopify-connectAnother');
        this.$router.replace({ name: 'connect_shop', params: { shop: 'shopify' } });
      },
      async goToAgencyDashboard() {
        track('connectShopify-agencyDashboard');
        await this.$apollo.query({
          query: REMOVE_SHOPIFY_INSTALL,
        });
        this.$router.push({ name: 'agency_dashboard' });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../sass/variables/_colors.sass'
  .waving-img
    margin-bottom: 33px
  .welcome
    &-title
      font-weight: 700
      font-size: 24px
      line-height: 32px
    &-intro
      font-size: 16px
      line-height: 28px
  .connect
    &-box
      padding: 24px 40px
      max-width: 580px
      border: 1px solid $om-gray-300
      flex-direction: column
      .account-email
        text-overflow: ellipsis
        width: 240px
        overflow: hidden

    &-platform-box
      width: 240px
    &-another
      color: $om-gray-700
      .brand-link-underline
        text-decoration-color: $om-gray-700

  .select-subdomain
    flex-direction: column

  .no-sub-account
    color: $om-red
</style>
